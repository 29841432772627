<template color="tapiz" class="mx-1">

    <v-row class="mx-1 mt-1" color="tapiz">

        <v-col cols="5" xs="8" sm="5"  md="5"  lg="6" xl="6" class="mt-2">

        <v-icon slot="icon" color="red" size="25" class="mx-2">flag</v-icon>
        <span class="text-subtitle-2 pt-3 mt-3">Vista de estadisticas por pais</span>
        

</v-col>

         
 <v-col cols="6" xs="6" sm="7" md="6" lg="6" xl="6" class="float-right text-lg-right text-md-right text-right">
   
   <v-flex class="d-inline-flex pb-0 pt-0">

       <v-select v-model="id_pais_activo" :items="PaisesArray" small  style="width:150px;height:30px;padding-top:0px;" @change="ListarFlotas()"
        label="Pais">
      </v-select>


        <v-select v-model="id_flota_vista" :items="SelectFlotas" x-small  label="Flotas"   class="mx-3" style="width:150px;height:30px;padding-top:0px;">
       </v-select> 
                   

      <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2 mt-2" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
            <v-icon dark>
            today
            </v-icon>
          </v-btn>

          </template>
      <span>Cambio fechas</span>
      </v-tooltip>


      <v-tooltip bottom class="float-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
                  <v-icon dark>logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>



    </v-flex>
 </v-col>


 
 <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Estadisticas por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>

   <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
  </v-dialog>


  <!--- Graficas de km  --> 
  <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-0">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span>Distancias KM</span> 

          <v-btn class="mx-4"  small @click="listarDistancias()"  rounded>Detalle </v-btn>
       
        <v-spacer></v-spacer></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" height="230" :options="chartOptionsKM" :series="seriesKM"></apexchart>
          </div>
            </v-card-text>
            </v-card>
            </v-col>


                <!---Dialogo Distancia-->
   <v-dialog v-model="dialogDistancia" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Distancias - Ralenti
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogDistancia=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>

                   <v-col  cols="4" sm="12" md="4">
                    <v-text-field
                        v-model="searchDistancia"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Resumen de distancias y ralenti</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersDistancia"
                            :items="itemsDistancias"
                            :items-per-page="10"
                            :search="searchDistancia"
                            class="elevation-1">


                            <template v-slot:[`item.distancia`]="{ item }">
                              <span v-if="item.distancia">{{ parseFloat(item.distancia ).toFixed(2) }}</span>
                            </template>

                            <template v-slot:[`item.ralenti_hrs`]="{ item }">
                              <span v-if="item.ralenti_hrs">{{ parseFloat(item.ralenti_hrs ).toFixed(2) }}</span>
                            </template>
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDistancia=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>




    <!--- Graficas de ralenti  --> 

    <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-n2">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span> Horas Ralenti</span> 

          <v-btn class="mx-4"  small @click="listarDistancias()"  rounded>Detalle</v-btn>
       
        <v-spacer></v-spacer></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" :height="heightRalenti" :options="chartOptionsRalenti" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesRalenti"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>




     <!--- Graficas de Excesos de velocidad  --> 

      <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-2">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span> Excesos de velocidad</span> 

          <v-btn :v-if="ArregloExcesos.length > 0  && itemsExceso.length > 0" class="mx-4"  small @click="listarExcesos()"  rounded>Detalle</v-btn>
          <v-spacer></v-spacer></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" height="230" :options="chartOptionsExcesos" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesExcesos"></apexchart>
          </div>
            </v-card-text>
            </v-card>
            </v-col>

            
     <!---Dialogo Excesos de velocidad-->
   <v-dialog v-model="dialogExceso" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Excesos de velocidad
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogExceso=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>

                   <v-col  cols="4" sm="12" md="4">
                    <v-text-field
                        v-model="searchExcesos"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Resumen de Excesos de velocidad</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersExcesos"
                            :items="itemsExceso"
                            :items-per-page="10"
                            :search="searchExcesos"
                            class="elevation-1">
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogExceso=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>


              <!--- Paradas permitidas--> 

              <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-2">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span> Paradas cumplimiento ruta</span> 

          <v-btn  class="mx-4"  small @click="listarParadas()"  rounded>Detalle</v-btn>
       
        <v-spacer></v-spacer></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar"  height="230" :options="chartOptionsParadas" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesParadas"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>


   <!---Dialogo Excesos de velocidad-->
   <v-dialog v-model="dialogParadas" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Paradas Autorizadas
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogParadas=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>

                   <v-col  cols="4" sm="12" md="4">
                    <v-text-field
                        v-model="searchParadas"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Resumen de paradas autorizadas</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersParadas"
                            :items="itemsParadas"
                            :items-per-page="10"
                            :search="searchParadas"
                            class="elevation-1">
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogParadas=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>






    

             <!--- Eficiencia de combustible-->

             <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-4">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0">
          
          <span> Eficiencia combustible</span> 

          <v-btn  class="mx-4"  small @click="listarEficiencia()"  rounded>Detalle</v-btn>

          <v-spacer></v-spacer>
       
      </v-card-title>

        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar"  height="230" :options="chartOptionsEficiencia" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesEficiencia"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>






  <!---Dialog Eficiencia-->
  <v-dialog v-model="dialogEficiencia" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de promedio de eficiencia de combustible
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogEficiencia=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>

                   <v-col  cols="4" sm="12" md="4">
                    <v-text-field
                        v-model="searchEficiencia"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Resumen de promedio de eficiencia /  kilometros por Galon</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersEficiencia"
                            :items="itemsEficiencia"
                            :items-per-page="10"
                            :search="searchEficiencia"
                            class="elevation-1">



                            <template v-slot:[`item.eficiencia`]="{ item }">
                              <span v-if="item.eficiencia">{{ parseFloat(item.eficiencia).toFixed(2) }}</span>
                            </template>

                            <template v-slot:[`item.kmxgl`]="{ item }">
                              <span v-if="item.kmxgl">{{ parseFloat(item.kmxgl ).toFixed(2) }}</span>
                            </template>


                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogParadas=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>







     <!--- rendimeinto km*gl de combustible-->

      <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-4">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0">
          <span> Rendimiento de Km por Gln</span> 

          <v-btn  class="mx-4"  small @click="listarEficiencia()"  rounded>Detalle</v-btn>


          <v-spacer></v-spacer>
        
         
      </v-card-title>

        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar"  height="230" :options="chartOptionsKMGL" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesKMGL"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>






    </v-row>


    
</template>

<script>

import axios from 'axios';

import { mapState,mapActions } from "vuex";

import { mapMutations } from "vuex";

import VueApexCharts from 'vue-apexcharts';


export default {
  components:{
    apexchart: VueApexCharts
    
    },
     data: () =>  ({

            id_pais_activo:'',
            PaisesArray:[],

            menuFecha1:false,
            fecha_inicio:new Date().toISOString().substr(0, 10),
            menuFecha2:false,
            fecha_fin:new Date().toISOString().substr(0, 10),
            date: new Date().toISOString().substr(0, 10),
            dialogInicio:true,
            dialogExit:false,


            monthNames : ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],

            ArrayFechasMensuales:[],

             //datos de km

             chartOptionsKM:  {
            chart: {
              type: 'bar',
              height: 230
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Ene',],
            },
            yaxis: {
              title: {
                text: 'Kilometros'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "  " + val + " "
                }
              }
            }
          },
            
              seriesKM:[{
                              name: 'Kilometros',
                              data: [0]
                             }],


                //---------------Datos de ralenti-----------------------
                heightRalenti:230,
                seriesRalenti:[{
                    name: 'Hrs Ralenti',
                    data: []
                }],

                chartOptionsRalenti:{
                                        chart: {
                                            type: 'bar',
                                            height:230
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },

  //---------------Datos de excesos----------------------

                                        seriesExcesos:[{
                                            name: 'Excesos ',
                                            data: []
                                        }],


                                        chartOptionsExcesos:{
                                          chart: {
                                            type: 'bar',
                                            height:230
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },

  //---------------Datos de paradas----------------------
                       seriesParadas:[{
                                        name: 'Paradas',
                                        data: []
                                    }],

                chartOptionsParadas:{
                                        chart: {
                                            type: 'bar',
                                            height:240
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },


                                        chartOptionsEficiencia:{
                                        chart: {
                                            type: 'bar',
                                            height:240
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },
                                        
                                        seriesEficiencia: [{
                                                          name: 'Eficiencia',
                                                          data: []
                                                      }],



                   //grafica de KM*GL

                   seriesKMGL: [{
                                         name: 'Eficiencia',
                                          data: []
                                 }],




                         chartOptionsKMGL:{
                                        chart: {
                                            type: 'bar',
                                            height:240
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },


              dialogExceso:false,
              searchExcesos:'',

              headersExcesos:[
                              { text: 'Vehiculo', value: 'nombre' },
                              { text: 'Placa', value: 'placa' },
                              { text: 'Flota', value: 'flota' },

                           
                              { text: 'Grave', value: 'Grave' },
                              { text: 'Critico', value: 'Critico' },
                              { text: 'Total Excesos', value:'total_exceso'}

                            ],
              itemsExceso:[],
              ArregloExcesos:[],

           
      




              dialogDistancia:false,
              searchDistancia:'',
              headersDistancia:[
                              { text: 'Vehiculo', value: 'nombre' },
                              { text: 'Placa', value: 'placa' },
                              { text: 'Flota', value: 'flota' },
                              { text: 'Distancia', value: 'distancia' },
                              { text: 'Ralenti hrs', value: 'ralenti_hrs' }
                          
                            ],
             itemsDistancias:[],



          dialogParadas:false,
          searchParadas:'',

          headersParadas:[
                              { text: 'Vehiculo', value: 'nombre' },
                              { text: 'Placa', value: 'placa' },
                              { text: 'Flota', value: 'flota' },
                              { text: 'Autorizadas', value: 'paradas' }
                            
                            ],
          itemsParadas:[],





          dialogEficiencia:false,
          searchEficiencia:'',
          headersEficiencia:[
                              { text: 'Vehiculo', value: 'nombre' },
                              { text: 'Placa', value: 'placa' },
                              { text: 'Flota', value: 'flota' },
                              { text: 'Eficiencia', value: 'eficiencia' },
                              { text: 'Km x gl', value: 'kmxgl' }
                            ],
           itemsEficiencia:[],
           arregloFlotas:[],
           SelectFlotas:[{text:'-Todas-', value:1}],
          id_flota_vista:1,
          id_todas_flotas:[],
                                                
    }),

computed: {

  ...mapState(['token','usuario'])

},

watch: {


 },
  created () {

    this.listarPaises();

    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),

            ...mapActions(['salir']),


        

                Procesar(){

                        let diffInMs   = new Date(this.fecha_fin) - new Date(this.fecha_inicio);
                        let diffInDays = diffInMs / (1000 * 60 * 60 * 24);


                        console.log('dias a generar:'+diffInDays);

                        let inicio=new Date(this.fecha_inicio +" 01:00:00").toISOString().substr(0, 10);
                        inicio.toLocaleString('en-US', { timeZone: 'America/Managua'});

                    
                        let fin=new Date(this.fecha_fin  +" 01:00:00").toISOString().substr(0, 10);
                        fin.toLocaleString('en-US', { timeZone: 'America/Managua'});


                        console.log('inicio :'+inicio);
                        console.log('fin :'+fin);


                        if(diffInDays < 340){
                            this.Arreglo_meses=[];
                            this.Arreglo_meses=this.getMonthNamesBetweenDates(inicio, fin);
                            console.log(this.Arreglo_meses);

                            this.ArrayFechasMensuales= this.getFirstDatesOfMonth(inicio, fin);

                            console.log( this.ArrayFechasMensuales);

                            this.dialogInicio=false;



                            this.GenerarDatosMesesDistancias();
                            this.GenerarDatosMesesRalenti();

                            this.GenerarDatosMesesExcesos();
                            this.GenerarDatosMesesParadas();

                            this.GenerarDatosMesesEficiencia();

                            this.GenerarDatosMesesKMGL();

                          
                          

                          

                          }else{
                          alert('solo puedes consultar intervalos menor a 1 año');

                        }




               },


                CalcularFechaHoy(){
                    let fecha=new Date();
                    fecha.toLocaleString('en-US', { timeZone: 'America/Managua'});
                    let mes=fecha.getMonth()+1;
                    if(mes<10)mes='0'+mes;
                    let dia=fecha.getDate();
                    if(dia<10)dia='0'+dia;
                    let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

                    return(textofecha);
                },

                cerrar(){
                    this.dialogExit=false;
                    localStorage.removeItem('usuario');
                    localStorage.removeItem('token');
                    
                    this.salir();
                },

                listarPaises () {

                                            
                        let config={headers:{token:this.token}};
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                        let me=this;

                        let RespuestaPaises=[];

                        axios.get('paises',  config
                        ).then(function(response){
                            // console.log(response);
                            RespuestaPaises=response.data;


                            me.configPaises=RespuestaPaises;

                            RespuestaPaises.map(function(x){
                                    me.PaisesArray.push({text:x.nombre_pais, value:x._id});
                                });

                                me.id_pais_activo=me.PaisesArray[0].value;

                                me.ListarFlotas();


                            me.ocultarLoading();


                        }).catch(function(error){
                        console.log(error);
                        });
                 },


                GenerarDatosMesesRalenti(){
                                let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }



                                
                                let config={headers:{ token:this.token}};
                                let me=this;
                                this.chartDataRalenti=[];
                                me.mostrarLoading({titulo:'listando datos'});

                                    axios.post('resumenes-mesRalenti',{ 
                                                'meses':me.ArrayFechasMensuales,
                                                'id_pais':me.id_pais_activo,
                                                'flotas':flota_ver
                                                },
                                            config
                                            ).then(response=>{
                                            
                                          //  console.log('resumenes de ralenti');
                                            //  console.log(response);

                                                let respuestas_=response.data;
                                          

                                                let meses_label=[];
                                                let valores_meses=[];


                                                respuestas_.map(function(r){

                                                  meses_label.push(r.mes);
                                                  valores_meses.push(Number(r.valor))
                                                
                                                });
                                                      

                                    me.ocultarLoading();
                                    me.dialog=false;


                                    me.seriesRalenti=[{
                        name: 'Hrs Ralenti',
                        data: valores_meses
                    }];

                    me.chartOptionsRalenti={
                                            chart: {
                                                type: 'bar',
                                                height:240
                                                },
                                                colors:['#FF4560', '#FEB019', '#F9DFDC'],
                                                plotOptions: {
                                                bar: {
                                                    horizontal: true,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                                },
                                                dataLabels: {
                                                enabled: true
                                                },
                                                stroke: {
                                                show: true,
                                                width: 2,
                                                colors: ['transparent']
                                                },
                                                xaxis: {
                                                categories: meses_label,
                                                },
                                                yaxis: {
                                                title: {
                                                    text: ''
                                                }
                                                },
                                                fill: {
                                                opacity: 1
                                                },
                                                tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                    return " " + val + ""
                                                    }
                                                }
                                                }
                                            };

                                }).catch(e=>{ console.log(e) });         


                },


                GenerarDatosMesesDistancias(){


                             let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }




                      let config={headers:{ token:this.token}};
                          let me=this;
                          this.chartDataRalenti=[];
                      me.mostrarLoading({titulo:'listando datos'});

                        axios.post('resumenes-mesDistancia',{ 
                                    'meses':me.ArrayFechasMensuales,
                                    'id_pais':me.id_pais_activo,
                                    'flotas':flota_ver
                                    },
                                config
                                ).then(response=>{
                                
                              //  console.log('resumenes de ralenti');
                                 // console.log(response);

                                    let respuestas_=response.data;
                              

                                    let meses_label=[];
                                    let valores_meses=[];


                                    respuestas_.map(function(r){

                                      meses_label.push(r.mes);
                                      valores_meses.push(Number(r.valor))
                                    
                                    });
                                          

                        me.ocultarLoading();
                        me.dialog=false;


                        me.seriesKM=[{
                name: 'Distancias',
                data: valores_meses
                }];

                me.chartOptionsKM={
                                chart: {
                                    type: 'bar',
                                    height:230
                                    },
                                    plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        columnWidth: '55%',
                                        endingShape: 'rounded'
                                    },
                                    },
                                    dataLabels: {
                                    enabled: true
                                    },
                                    stroke: {
                                    show: true,
                                    width: 2,
                                    colors: ['transparent']
                                    },
                                    xaxis: {
                                    categories: meses_label,
                                    },
                                    yaxis: {
                                    title: {
                                        text: ''
                                    }
                                    },
                                    fill: {
                                    opacity: 1
                                    },
                                    tooltip: {
                                    y: {
                                        formatter: function (val) {
                                        return " " + val + ""
                                        }
                                    }
                                    }
                                };

                    }).catch(e=>{ console.log(e) });         


                },



                GenerarDatosMesesExcesos(){ 


                            let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }




                          let config={headers:{ token:this.token}};
                          let me=this;
                          this.chartDataRalenti=[];
                      me.mostrarLoading({titulo:'listando datos'});

                        axios.post('eventos-meses',{ 
                                    'meses':me.ArrayFechasMensuales,
                                    'id_pais':me.id_pais_activo,
                                    'eventos':['Exceso'],
                                    'flotas':flota_ver
                                    },
                                config
                                ).then(response=>{
                                
                                console.log('resumenes de excesos');
                                  console.log(response);

                                    let respuestas_=response.data;
                              

                                    let meses_label=[];
                                    let valores_meses=[];


                                    respuestas_.map(function(r){

                                      meses_label.push(r.mes);
                                      valores_meses.push(Number(r.valor))
                                    
                                    });


                                    me.seriesExcesos=[{
                                                  name: 'Excesos',
                                                  data: valores_meses
                                                  }];



                                  me.chartOptionsExcesos={
                                                  chart: {
                                                      type: 'bar',
                                                      height:230
                                                      },
                                                      colors:[ '#FEB019', '#F9DFDC'],
                                                      plotOptions: {
                                                      bar: {
                                                          horizontal: false,
                                                          columnWidth: '55%',
                                                          endingShape: 'rounded'
                                                      },
                                                      },
                                                      dataLabels: {
                                                      enabled: true
                                                      },
                                                      stroke: {
                                                      show: true,
                                                      width: 2,
                                                      colors: ['transparent']
                                                      },
                                                      xaxis: {
                                                      categories: meses_label,
                                                      },
                                                      yaxis: {
                                                      title: {
                                                          text: ''
                                                      }
                                                      },
                                                      fill: {
                                                      opacity: 1
                                                      },
                                                      tooltip: {
                                                      y: {
                                                          formatter: function (val) {
                                                          return " " + val + ""
                                                          }
                                                      }
                                                      }
                                                  };

                                                

                                                  me.ocultarLoading();

                    }).catch(e=>{ console.log(e) });         





                  
                },


                GenerarDatosMesesParadas(){


                  
                  let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }




                  let config={headers:{ token:this.token}};
                                let me=this;
                                this.chartDataRalenti=[];
                            me.mostrarLoading({titulo:'listando datos'});

                            axios.post('paradas-meses',{ 
                                                        'meses':me.ArrayFechasMensuales,
                                                        'id_pais':me.id_pais_activo,
                                                        'autorizado':true,
                                                        'flotas':flota_ver
                                                        },
                                                    config
                                                    ).then(response=>{
                                            
                                           console.log('resumenes de paradas');
                                            console.log(response);

                                                let respuestas_=response.data;
                                          

                                                let meses_label=[];
                                                let valores_meses=[];


                                                respuestas_.map(function(r){

                                                  meses_label.push(r.mes);
                                                  valores_meses.push(Number(r.valor))
                                                
                                                });
                                                      

                                    me.ocultarLoading();
                                    me.dialog=false;


                                    me.seriesParadas=[{
                                                        name: 'Paradas',
                                                        data: valores_meses
                                                    }];

                    me.chartOptionsParadas={
                                            chart: {
                                                type: 'bar',
                                                height:230
                                                },
                                               
                                                colors:['#00E396', '#FEB019', '#F9DFDC'],
                                                plotOptions: {
                                                bar: {
                                                    horizontal: true,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                                },
                                                dataLabels: {
                                                enabled: true
                                                },
                                                stroke: {
                                                show: true,
                                                width: 2,
                                                colors: ['transparent']
                                                },
                                                xaxis: {
                                                categories: meses_label,
                                                },
                                                yaxis: {
                                                title: {
                                                    text: ''
                                                }
                                                },
                                                fill: {
                                                opacity: 1
                                                },
                                                tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                    return " " + val + ""
                                                    }
                                                }
                                                }
                                            };

                                }).catch(e=>{ console.log(e) });  


                },






                GenerarDatosMesesEficiencia(){


                             let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }


                                

                                let config={headers:{ token:this.token}};
                                let me=this;
                               // this.chartOptionsEficiencia=[];
                            me.mostrarLoading({titulo:'listando datos'});

                            axios.post('resumenes-mesEficiencia',{ 
                                    'meses':me.ArrayFechasMensuales,
                                    'id_pais':me.id_pais_activo,
                                    'flotas':flota_ver
                                    },
                                config
                                ).then(response=>{

                                   

                                   let respuestas_=response.data;
                                 
                                   let meses_label=[];
                                    let valores_meses=[];


                                    respuestas_.map(function(r){

                                      meses_label.push(r.mes);
                                      valores_meses.push(Number(r.valor))
                                    
                                    });





                                    me.seriesEficiencia=[{
                                                  name: 'Eficiencia',
                                                  data: valores_meses
                                                  }];

                                                  



                                  me.chartOptionsEficiencia={
                                                  chart: {
                                                      type: 'bar',
                                                      height:230
                                                      },
                                                    
                                                      plotOptions: {
                                                      bar: {
                                                          horizontal: false,
                                                          columnWidth: '55%',
                                                          endingShape: 'rounded'
                                                      },
                                                      },
                                                      dataLabels: {
                                                      enabled: true
                                                      },
                                                      stroke: {
                                                      show: true,
                                                      width: 2,
                                                      colors: ['transparent']
                                                      },
                                                      xaxis: {
                                                      categories: meses_label,
                                                      },
                                                      yaxis: {
                                                      title: {
                                                          text: ''
                                                      }
                                                      },
                                                      fill: {
                                                      opacity: 1
                                                      },
                                                      tooltip: {
                                                      y: {
                                                          formatter: function (val) {
                                                          return " " + val + ""
                                                          }
                                                      }
                                                      }
                                                  };

                                   




                                }).catch(e=>{ console.log(e) });     





                },

                GenerarDatosMesesKMGL(){



                        let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }


                  let config={headers:{ token:this.token}};
                                let me=this;
                               // this.chartOptionsEficiencia=[];
                            me.mostrarLoading({titulo:'listando datos'});

                            axios.post('resumenes-mesKMGL',{ 
                                    'meses':me.ArrayFechasMensuales,
                                    'id_pais':me.id_pais_activo,
                                    'flotas':flota_ver
                                    },
                                config
                                ).then(response=>{

                                   

                                   let respuestas_=response.data;
                                   console.log('combustible');
                                   console.log(response);

                                   let meses_label=[];
                                    let valores_meses=[];


                                    respuestas_.map(function(r){

                                      meses_label.push(r.mes);
                                      valores_meses.push(Number(r.valor))
                                    
                                    });








                                    me.seriesKMGL=[{
                                                  name: 'KM x GL',
                                                  data: valores_meses
                                                  }];

                                                  
                                  me.chartOptionsKMGL={
                                                  chart: {
                                                      type: 'bar',
                                                      height:230
                                                      },
                                                    
                                                      plotOptions: {
                                                      bar: {
                                                          horizontal: false,
                                                          columnWidth: '55%',
                                                          endingShape: 'rounded'
                                                      },
                                                      },
                                                      dataLabels: {
                                                      enabled: true
                                                      },
                                                      stroke: {
                                                      show: true,
                                                      width: 2,
                                                      colors: ['transparent']
                                                      },
                                                      xaxis: {
                                                      categories: meses_label,
                                                      },
                                                      yaxis: {
                                                      title: {
                                                          text: ''
                                                      }
                                                      },
                                                      fill: {
                                                      opacity: 1
                                                      },
                                                      tooltip: {
                                                      y: {
                                                          formatter: function (val) {
                                                          return " " + val + ""
                                                          }
                                                      }
                                                      }
                                                  };

                                   




                                }).catch(e=>{ console.log(e) });     





                 },


              
              getMonthNamesBetweenDates(startDate, endDate) {
                        // Parse the start and end dates
                        let start = new Date(startDate);
                        start.setUTCHours(10, 0, 0, 0);
                        let end = new Date(endDate);
                        end.setUTCHours(10, 0, 0, 0);

                        // Create an array to store the month names
                        const monthNames = [];

                        // Loop through each month and add the name to the array
                        let currentDate = new Date(start);
                        currentDate.setUTCHours(10, 0, 0, 0);

                        while (currentDate <= end) {                                        // long
                            const monthName = currentDate.toLocaleString('default', { month: 'short', timeZone: 'America/Managua' });
                            monthNames.push(monthName);

                            currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
                        }

                        return monthNames;
              },


             getFirstDatesOfMonth(startDate, endDate) {
                    let firstDates = [];
                    
                    let currentDate = new Date(startDate);
                    currentDate.setUTCHours(10, 0, 0, 0);
                    currentDate.setDate(1); // Set the date to the first day of the month
                    
                    let lastDate = new Date(endDate);
                    lastDate.setUTCHours(10, 0, 0, 0);

                    while (currentDate <= lastDate) {
                        firstDates.push(new Date(currentDate)); // Add a new Date object to the array
                        currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
                    }

                    return firstDates;
             },


            ListarFlotas(){

                    this.arregloFlotas=[];
                    this.SelectFlotas=[];
                    this.id_todas_flotas=[];
                  
                    this.SelectFlotas=[{text:'-Todas-', value:1}];
                    this.id_flota_vista=1;
                        

            

                    this.mostrarLoading({titulo:'Accediendo a datos'});
                    let config={headers:{token:this.token}};
                    let me=this;

                    axios.get(`flotas/${this.id_pais_activo}`,  config
                                ).then(function(response){
                                //  console.log(response);
                                  me.arregloFlotas=response.data;
                                  me.arregloFlotas.map(function(x){
                                  me.SelectFlotas.push({text:x.nombre_flota, value:x.nombre_flota});
                                  me.id_todas_flotas.push(x.nombre_flota);
                            });

                            

                            me.ocultarLoading();

                            }).catch(function(error){
                            console.log(error);
                          });



            },



            // al abrir el detalle de excesos se mostrara tabla con resumen

            listarExcesos(){

              let me=this;
              this.itemsExceso=[];

            
                let config={headers:{ token:this.token}};
               
                me.mostrarLoading({titulo:'listando datos'});

                       axios.post('eventos',{ 
                                  'id_pais':this.id_pais_activo,
                                  'desde':this.fecha_inicio,
                                  'hasta':this.fecha_fin,
                                  'tipo':'Exceso'
                              },
                            config
                            ).then(response=>{

                              let respuesta=response.data;

                                respuesta.map(function(x){
                                  if(x.velocidad > 79){
                                    me.ArregloExcesos.push(x);
                                  }
                                });

                               

                                me.ocultarLoading();

                              me.ProcesarExcesos();

                          }).catch(e=>{ console.log(e) });


            },

            ProcesarExcesos(){

            
             
              this.mostrarLoading({titulo:'accediendo a datos'});

             

              let me=this;

              let respuestaExceso=me.ArregloExcesos;


                                        
            me.vehiculos_excesos=[];
            me.vehiculos_excesos.length=0;



            console.log('aqui vacios');
            console.log(me.vehiculos_excesos);


            //sacamos listado de vehiculos con excesos detectados

          respuestaExceso.map(function(x){

                      

                            let encontrado=false;

                            me.vehiculos_excesos.map(function(y){

                              if(x.id_vehiculo!=null){

                                  if(y._id==x.id_vehiculo._id){
                                    encontrado=true;
                                  }

                              }

                            });

                            if((encontrado==false)&&(x.id_vehiculo!=null)){


                              if((x.id_vehiculo.flota==me.id_flota_vista)||(me.id_flota_vista==1)){

                                      let item_camion={
                                                  '_id':x.id_vehiculo._id,'nombre':x.id_vehiculo.nombre, 'placa':x.id_vehiculo.placa, 
                                                'flota':x.id_vehiculo.flota, 'total_exceso':0};

                                                if(x.id_vehiculo.id_subflota_veh && x.id_vehiculo.id_subflota_veh!=undefined){
                                                  item_camion.id_subflota_veh=x.id_vehiculo.id_subflota_veh;
                                                }


                                  me.vehiculos_excesos.push(item_camion);

                                }


                                
                              }
                    });

             
          let datos_local_barra=[];
          datos_local_barra.push({'name':'Grave', 'data':[]});
          datos_local_barra.push({'name':'Critico', 'data':[]});

                  


                 
          datos_local_barra.map(function(x){

            me.vehiculos_excesos.map(function(y){

                            

            me.ArregloExcesos.map(function(z){

              if(z.id_vehiculo!=null){

              

                        if(y._id==z.id_vehiculo._id){


                            let clasificacion=[];

                                me.arregloFlotas.map(function(b){
                                if(b.nombre_flota==y.flota){
                                    clasificacion=b.clasificacion_velocidad;
                                }
                                });


                              clasificacion.map(function(a){

                                let campo=a.nombre;
                                
                                if(y[campo]){
                                  // console.log('ya existe');
                                }else{
                                    y[campo]=0;
                                }
                                
                                if((a.nombre==x.name)&&(z.velocidad  >= a.desde)&&(z.velocidad  <=  a.hasta)){
                                  if(z.velocidad){

                                  
                                    let campo=a.nombre;
                                      y[campo]++;
                                      y.total_exceso++;
                                  }
                                }
                              });//fin de clasificacion

                        }//si es mimso vehiculo


                  }
                  
                }); //fin de excesos



                  }); //fin de vehiculos


                      
                 
                    }); //fin de datos de barras



                    me.itemsExceso=me.vehiculos_excesos;


                  
             
                    this.dialogExceso=true;

                    me.ocultarLoading();
             
           },




           listarDistancias(){



            let flota_ver=[];
                                
              if(this.id_flota_vista==1){
                flota_ver=this.id_todas_flotas;
              }else{
                flota_ver=[this.id_flota_vista];
              }



              let me=this;

              me.dialogDistancia=true;

              me.itemsDistancias=[];

              let config={headers:{ token:this.token}};
               
               me.mostrarLoading({titulo:'listando datos'});

                      axios.post('resumenes-vehiculos',{ 
                                 'id_pais':this.id_pais_activo,
                                 'desde':this.fecha_inicio,
                                 'hasta':this.fecha_fin,
                                 'flotas':flota_ver
                             },
                           config
                           ).then(response=>{

                            let respuesta=response.data;

                            me.itemsDistancias=respuesta;
                         
                            me.ocultarLoading();

                        }).catch(e=>{ console.log(e) });


           },




            listarParadas(){


              let flota_ver=[];
                                
                                if(this.id_flota_vista==1){
                                  flota_ver=this.id_todas_flotas;
                                }else{
                                  flota_ver=[this.id_flota_vista];
                                }


              
              let me=this;

              me.dialogParadas=true;

              me.itemsParadas=[];

              let config={headers:{ token:this.token}};
               
               me.mostrarLoading({titulo:'listando datos'});

                      axios.post('paradas-resumenunidad',{ 
                                 'id_pais':this.id_pais_activo,
                                 'desde':this.fecha_inicio,
                                 'hasta':this.fecha_fin,
                                 'flotas':flota_ver
                             },
                           config
                           ).then(response=>{

                            let respuesta=response.data;

                            me.itemsParadas=respuesta;
                         
                            me.ocultarLoading();

                        }).catch(e=>{ console.log(e) });

            },



            listarEficiencia(){ 


                   let flota_ver=[];
                                    
                  if(this.id_flota_vista==1){
                    flota_ver=this.id_todas_flotas;
                  }else{
                    flota_ver=[this.id_flota_vista];
                  }




                   let me=this;

                  me.dialogEficiencia=true;

                  me.itemsEficiencia=[];

                  let config={headers:{ token:this.token}};
                  
                  me.mostrarLoading({titulo:'listando datos'});

                          axios.post('resumenes-eficienciavehiculo',{ 
                                    'id_pais':this.id_pais_activo,
                                    'desde':this.fecha_inicio,
                                    'hasta':this.fecha_fin,
                                    'flotas':flota_ver
                                },
                              config
                              ).then(response=>{

                                let respuesta=response.data;

                                me.itemsEficiencia=respuesta;
                            
                                me.ocultarLoading();

                            }).catch(e=>{ console.log(e) });



            }





        }
}
</script>

